import React from 'react';
// import ExamplePDFInvoice from './generateInvoice/ExamplePDFInvoice';
import PDFResume from './generateResume/PDFResume';

const Resume = () => {
  return (
    <>
      {/* <ExamplePDFInvoice /> */}
      <PDFResume />
    </>
  );
};

export default Resume;
